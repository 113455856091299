<template>
  <div class="lottery">
    <div class="content">
      <div class="back" @click="back">
        <ArrowLeftOutlined />
      </div>
      <div class="type-title">{{ $t("LuckyWheelLottery") }}</div>
      <div class="flex-wrap">
        <div class="activity">
          <div class="title">
            {{ dataInfo.lotteryName }}
          </div>
          <div class="chassis">
            <div class="prize">
              <div class="prize-list" ref="prizeWrap" :style="bgColor">
                <div
                  class="prize-item"
                  v-for="(item, index) in prizeList"
                  :key="index"
                  :style="prizeStyle(index)"
                >
                  <div class="name" v-if="item.prizeId > 0">
                    <img
                      :src="
                        require(`@/assets/image/lottery/prize-${item.imgIndex}.png`)
                      "
                      alt="prize"
                    />
                  </div>
                  <div class="name-none" v-else>
                    <p>{{ item.prizeName }}</p>
                  </div>
                </div>
              </div>
              <div class="prize-star" @click="start"></div>
            </div>
            <div class="number">
              {{ $t("NLotteryOpportunities", { number: dataInfo.remain }) }}
            </div>
          </div>
        </div>
        <div class="explain">
          <div class="title">
            <span
              >{{ $t("LotteryDescription") }}
              <!-- 抽奖说明 -->
            </span>
          </div>
          <div class="row">
            {{ $t("WelcomeToLuckyLotteryEvent") }}
            <!-- 欢迎来到幸运大抽奖活动！ -->
          </div>
          <div class="row">
            {{ $t("LotteryPeriod") }}：<span
              >{{ dateFormat(dataInfo.startTime) }} ～
              {{ dateFormat(dataInfo.endTime) }}</span
            >
            <!-- 抽奖时间段 -->
          </div>
          <div class="row" v-if="dataInfo.intro">
            {{ $t("LotteryDescription") }}：<span>{{ dataInfo.intro }}</span>
            <!-- 抽奖说明 -->
          </div>
          <div class="title">
            <span
              >{{ $t("AwardDescription") }}
              <!-- 奖项说明 -->
            </span>
          </div>
          <div class="no-swiper" v-if="dataInfo.LotteryPrizeList.length <= 3">
            <div
              class="swiper-item"
              v-for="(item, index) in dataInfo.LotteryPrizeList"
              :key="index"
            >
              <div class="cover">
                <img :src="item.imgs" alt="cover" />
              </div>
              <div class="text">
                <div class="name">{{ item.prizeName }}</div>
                <div class="number">
                  {{ $t("NumberOfPrizes") }}：<span
                    >{{ item.quantity }}{{ $t("LB_My_Ge") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <swiper
              :modules="modules"
              :loop="true"
              :navigation="true"
              :autoplay="{
                autoplay: true,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
                delay: 3000,
              }"
              :slidesPerView="3"
              :spaceBetween="9"
            >
              <swiper-slide
                v-for="(item, index) in dataInfo.LotteryPrizeList"
                :key="index"
              >
                <div class="swiper-item">
                  <div class="cover">
                    <img :src="item.imgs" alt="cover" />
                  </div>
                  <div class="text">
                    <div class="name">{{ item.prizeName }}</div>
                    <div class="number">
                      {{ $t("NumberOfPrizes") }}：<span
                        >{{ item.quantity }}{{ $t("LB_My_Ge") }}</span
                      >
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="title">
            <span
              >{{ $t("AwardReceivingInstructions") }}
              <!-- 领奖说明 -->
            </span>
          </div>
          <div class="row">
            {{ $t("AwardDeadline") }}：<span>{{
              dateFormat(dataInfo.prizeExpiryTime)
            }}</span>
            <!-- 领奖截止时间 -->
          </div>
          <div class="row" v-if="dataInfo.prizeIntro">
            {{ $t("AwardReceivingInstructions") }}：
            <div class="vhtml" v-html="dataInfo.prizeIntro"></div>
            <!-- 领奖说明 -->
          </div>
          <div class="title">
            <span>{{ $t("WinningRecord") }}</span>
          </div>
          <div class="row" v-if="!dataInfo.winMemberList.length">
            {{ $t("YouHaventWonYet") }}
            <!-- 您暂未中奖！ -->
          </div>
          <div class="row" v-else>
            <div
              class="win-box"
              v-for="item in dataInfo.winMemberList"
              :key="item.prizeId"
            >
              <div class="cover">
                <img :src="item.imgs" alt="cover" />
              </div>
              <div class="text">
                <div class="p name">{{ item.prizeName }}</div>
                <div class="p">
                  {{ $t("RedemptionCode") }}:{{ item.redeemCode }}
                </div>
                <div class="p mb0">
                  {{ $t("WinningTime") }}:{{ dateFormat(item.lotteryTime) }}
                </div>
                <div class="status">
                  <div class="award green" v-if="item.winMemberInfo">
                    {{ $t("RedeemedPrize") }}
                  </div>
                  <div
                    class="award blue"
                    v-else
                    @click="$refs.winningRef.showDialog(item)"
                  >
                    {{ $t("GoRedeemPrize") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <winning
      ref="winningRef"
      :dataSource="dataInfo"
      :departmentList="departmentList"
      @ok="getWinList"
    />
  </div>
</template>

<script>
import {
  onMounted,
  onUnmounted,
  ref,
  reactive,
  toRefs,
  computed,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getDepartmentTree } from "@/api/user";
import { lotteryDetail, lotteryDraw } from "@/api/lottery";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Autoplay, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import winning from "./winning.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    winning,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      lotteryId: parseInt(route.query.lotteryId || 0),
      dataInfo: {
        LotteryPrizeList: [],
        winMemberList: [],
      },
      departmentList: [],
      prizeList: [],
      isRunning: false,
      baseRunAngle: 360 * 5,
      prizeIndex: 0,
    });

    const back = () => {
      router.go(-1);
    };

    // 获取部门树
    getDepartmentTree().then((res) => {
      state.departmentList = res.data;
    });

    const prizeWrap = ref(null);
    const emptyPrizeList = [
      {
        prizeId: -1,
        prizeName: proxy.$t("ThankYouForParticipating"),
      },
      {
        prizeId: -2,
        prizeName: proxy.$t("WishingYouGoodLuck"),
      },
      {
        prizeId: -3,
        prizeName: proxy.$t("KeepWorkingHard"),
      },
      {
        prizeId: -4,
        prizeName: proxy.$t("DonNotLoseHeart"),
      },
      {
        prizeId: -5,
        prizeName: proxy.$t("NextTimeDefinitely"),
      },
      {
        prizeId: -6,
        prizeName: proxy.$t("WinningImmediately"),
      },
    ];
    lotteryDetail(state.lotteryId).then((res) => {
      if (res.ret == 0) {
        state.dataInfo = res.data;
        let prizeList = JSON.parse(
          JSON.stringify(res.data.LotteryPrizeList || [])
        );
        if (prizeList.length == 1) {
          prizeList.push(prizeList[0]);
          prizeList.push(prizeList[0]);
        } else if (prizeList.length == 2) {
          prizeList.push(prizeList[0]);
        }
        let tempPrizeList = [];
        for (let i = 0; i < prizeList.length; i++) {
          prizeList[i].imgIndex = i + 1;
          tempPrizeList.push(prizeList[i]);
          tempPrizeList.push(emptyPrizeList[i]);
        }
        state.prizeList = tempPrizeList;
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: res.msg,
            path: "/",
            name: proxy.$t("CM_Index"),
          },
        });
      }
    });

    // 平均每个奖品角度
    const rotateAngle = computed(() => {
      const _degree = 360 / state.prizeList.length;
      return _degree;
    });

    // 要执行总角度数
    const totalRunAngle = computed(() => {
      return (
        state.baseRunAngle +
        360 -
        state.prizeIndex * rotateAngle.value -
        rotateAngle.value / 2
      );
    });

    // 计算绘制转盘背景
    const bgColor = computed(() => {
      const _len = state.prizeList.length;
      const colorList = ["#fadfd4", "#ffffff"];
      let colorVal = "";
      for (let i = 0; i < _len; i++) {
        colorVal += `${colorList[i % 2]} ${rotateAngle.value * i}deg ${
          rotateAngle.value * (i + 1)
        }deg,`;
      }
      return `
            background: conic-gradient(${colorVal.slice(0, -1)});
          `;
    });

    // 每个奖品布局
    const prizeStyle = computed(() => {
      const _degree = rotateAngle.value;
      return (i) => {
        return `
              width: ${2 * 191 * Math.sin(((_degree / 2) * Math.PI) / 180)}px;
              height: 191px;
              transform: rotate(${_degree * i + _degree / 2}deg);
              transform-origin: 50% 100%;
            `;
      };
    });

    onMounted(() => {
      prizeWrap.value.style = `${bgColor.value} transform: rotate(-${
        rotateAngle.value / 2
      }deg)`;
    });

    onUnmounted(() => {
      prizeWrap.value &&
        prizeWrap.value.removeEventListener("transitionend", stopRun);
    });

    const isEven = (number) => {
      return Math.abs(number) % 2 === 0;
    };

    const winningRef = ref(null);

    const start = () => {
      if (state.dataInfo.lotteryStatus == 1) {
        proxy.$message.info(proxy.$t("LotteryHasNotStartedYet"));
        return false;
      }
      if (state.dataInfo.lotteryStatus == 3) {
        proxy.$message.info(proxy.$t("LotteryHasEnded"));
        return false;
      }
      if (state.dataInfo.remain <= 0) {
        proxy.$message.info(proxy.$t("LotteryOpportunityHasBeenUsedUp"));
        return false;
      }
      if (!state.isRunning) {
        state.isRunning = true;
        lotteryDraw(state.lotteryId).then((res) => {
          state.dataInfo.remain = res.data.remain;
          let randomNum =
            Math.floor(Math.random() * state.prizeList.length + 1) - 1;
          if (res.data.prizeId) {
            if (!isEven(randomNum)) {
              randomNum += 1;
            }
            setTimeout(() => {
              winningRef.value.showDialog(res.data);
            }, 3100);
          } else {
            if (isEven(randomNum)) {
              randomNum += 1;
            }
            setTimeout(() => {
              proxy.$message.info(res.data.tips);
            }, 3100);
          }
          state.prizeIndex = randomNum;
          startRun();
        });
      }
    };

    const startRun = () => {
      // 设置动效
      prizeWrap.value.style = `
            ${bgColor.value}
            transform: rotate(${totalRunAngle.value}deg);
            transition: all 3s ease;
          `;
      // 监听transition动效停止事件
      prizeWrap.value.addEventListener("transitionend", stopRun);
    };

    const stopRun = (e) => {
      state.isRunning = false;
      prizeWrap.value.style = `
            ${bgColor.value}
            transform: rotate(${totalRunAngle.value - state.baseRunAngle}deg);
          `;
    };

    const getWinList = () => {
      lotteryDetail(state.lotteryId).then((res) => {
        state.dataInfo.winMemberList = res.data.winMemberList || [];
      });
    };

    return {
      dateFormat,
      ...toRefs(state),
      back,
      bgColor,
      prizeStyle,
      prizeWrap,
      winningRef,
      start,
      modules: [Autoplay, Navigation],
      getWinList,
    };
  },
};
</script>

<style lang="less" scoped>
.lottery {
  background-color: #fafafa;
  padding: 24px 0 58px;
  .content {
    .mixinWrap();
    height: 951px;
    background-image: url("../../assets/image/lottery/turntable-wrap-bg.png");
    background-size: 100% 100%;
    padding: 40px 40px 35px 40px;
    box-sizing: border-box;
    position: relative;
    .back {
      position: absolute;
      top: 42px;
      left: 40px;
      font-size: 44px;
      color: #fff;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
    }
    @font-face {
      font-family: youshe;
      src: url("../../assets/fonts/YouSheBiaoTiHei.ttf");
    }
    .type-title {
      font-size: 36px;
      font-family: youshe !important;
      line-height: 47px;
      color: #e8fc35;
      text-align: center;
      margin-bottom: 60px;
    }
    .flex-wrap {
      .mixinFlex(space-between);
    }
  }
}
.activity {
  width: calc(100% - 581px);
  .title {
    width: 336px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin: 0 auto 12px;
    .mixinEllipsis(52px, 2);
  }
  .chassis {
    width: 539px;
    height: 557px;
    position: relative;
    background: url("../../assets/image/lottery/turntable-chassis.png")
      no-repeat center / 100% 100%;
    .number {
      font-size: 16px;
      font-weight: 700;
      color: #333;
      position: absolute;
      bottom: 0;
      left: 61px;
      width: 389px;
      height: 73px;
      .mixinFlex(center; center);
    }
  }
  .prize {
    width: 438px;
    height: 438px;
    .mixinFlex(center; center);
    background: url("../../assets/image/lottery/turntable-bg.png") no-repeat
      center / 100% 100%;
    position: absolute;
    transform: scale(0.9);
    top: 22px;
    left: 35px;
    &-list {
      width: 382px;
      height: 382px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      .prize-item {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        .mixinFlex(center; center);
        padding-bottom: 70px;
        .name {
          // width: 61%;
          // height: 40%;
          overflow: hidden;
          .mixinFlex(center; center);
          p {
            color: #ff4f2f;
            font-size: 12px;
            margin: 0;
            max-height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: 16px;
            min-height: 20px;
            word-wrap: break-word;
          }
          img {
            height: 80px;
          }
        }
        .name-none {
          width: 61%;
          height: 60%;
          overflow: hidden;
          .mixinFlex(center; center);
          p {
            color: #ff4f2f;
            font-size: 14px;
            margin: 0;
            // width: 14px;
            line-height: 16px;
          }
        }
      }
    }
    &-star {
      width: 98px;
      height: 125px;
      background: url("../../assets/image/lottery/turntable-pointer.png")
        no-repeat center / 100% 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: -30px;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }
  }
}
.explain {
  width: 557px;
  height: 752px;
  opacity: 1;
  border-radius: 4px;
  background: #ffdf87;
  box-shadow: 0 0 12px rgba(189, 94, 37, 0.5);
  padding: 10px 30px;
  overflow-y: auto;
  .title {
    margin: 20px 0;
    .mixinFlex(center; center);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 18px;
      width: 100%;
      height: 4px;
      background: linear-gradient(
        90deg,
        rgba(250, 255, 206, 0) 0%,
        rgba(255, 78, 45, 1) 50%,
        rgba(250, 255, 206, 0) 100%
      );
      z-index: 1;
    }
    span {
      height: 40px;
      background-color: #ff4e2d;
      border: 2px solid #e8fc35;
      border-radius: 28px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      padding: 0 32px;
      z-index: 2;
    }
  }
  .row {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;
    .vhtml,
    span {
      font-size: 14px;
      font-weight: 400;
    }
    .win-box {
      margin-bottom: 12px;
      background-color: #fff;
      border-radius: 4px;
      padding: 8px;
      .mixinFlex(space-between);
      .cover {
        .mixinImgWrap(160px; 90px);
        border-radius: 4px;
      }
      .text {
        position: relative;
        width: calc(100% - 168px);
        .p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 5px;
          &.name {
            .mixinEllipsis(40px; 2);
          }
          &.mb0 {
            margin-bottom: 0;
          }
        }
        .status {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #999999;
          .mixinFlex(flex-start;center);
          .issue {
            margin-left: 12px;
          }
          .award {
            border-radius: 2px;
            padding: 0 5px;
            font-size: 14px;
            &.green {
              color: #22cf33;
              height: 20px;
              line-height: 18px;
              border: 1px solid #cccccc;
            }
            &.blue {
              background-color: #377dff;
              height: 20px;
              line-height: 20px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .swiper-item {
    width: 160px;
    height: 174px;
    background-color: #fff;
    border-radius: 4px;
    .cover {
      .mixinImgWrap(160px; 90px);
    }
    .text {
      padding: 6px 10px;
      .name {
        font-size: 14px;
        font-weight: 700;
        .mixinEllipsis(44px; 2);
        color: #333;
        margin-bottom: 6px;
      }
      .number {
        font-size: 14px;
        color: #333;
        span {
          font-weight: 700;
          color: #ff4e2d;
        }
      }
    }
  }
  ::v-deep(.swiper) {
    .swiper-button-next,
    .swiper-button-prev {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      &::after {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .no-swiper {
    .mixinFlex(flex-start);
    .swiper-item {
      margin-right: 9px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
